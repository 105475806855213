<template>
  <b-overlay
    :show="loadingOverlay"
    rounded="sm"
  >
    <b-card>
      <b-row>
        <div class="position-relative">
          <div class="profile-img-container d-flex align-items-center">
            <div class="profile-img">
              <b-img
                :src="getlogo(user_data.id)"
                rounded
                fluid
                alt="profile photo"
              />
            </div>
            <!-- profile title -->
            <div class="profile-title ml-3">
              <h2>
                {{ user_data.nome }}
              </h2>
              <p>
                {{ user_data.perfil }}
              </p>
            </div>
            <!--/ profile title -->
          </div>
        </div>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BRow, BOverlay, BCard, BImg,
} from 'bootstrap-vue'

export default {

  components: {
    BRow,
    BOverlay,
    BCard,
    BImg,
  },

  data() {
    return {
      loadingOverlay: false,
      user_data: [],
    }
  },

  created() {
    this.getMeuAgente(this.$route.params.id)
  },

  methods: {
    getlogo(id) {
      return `${process.env.VUE_APP_STATIC}agente/${id}/logo.png`
    },

    getMeuAgente(id) {
      this.loadingOverlay = true
      this.$store.dispatch('meu-agente-spot/getMeuAgenteSobre', id)
        .then(res => { this.user_data = res.data })
        .finally(() => {
          this.loadingOverlay = false
        })
    },
  },
}
</script>
